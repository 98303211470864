import React from 'react';

const IconTwitter = () => (
    <svg height="2500" width="2500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g fill="none" fill-rule="evenodd"><path d="M256 411.12L0 202.667 256 0z" fill="currentColor"/><path d="M256 411.12l256-208.453L256 0z" fill="currentColor"/><circle cx="256" cy="362.667" fill="currentColor" r="149.333"/><path d="M121.037 298.667c23.968-50.453 75.392-85.334 134.963-85.334s110.995 34.881 134.963 85.334H121.037z" fill="currentColor"/></g></svg>

);

export default IconTwitter;


// import React from 'react';

// const IconCodepen = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     role="img"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//     className="feather feather-codepen">
//     <polygon points="12 2 22 8.5 22 15.5 12 22 2 15.5 2 8.5 12 2"></polygon>
//     <line x1="12" y1="22" x2="12" y2="15.5"></line>
//     <polyline points="22 8.5 12 15.5 2 8.5"></polyline>
//     <polyline points="2 15.5 12 8.5 22 15.5"></polyline>
//     <line x1="12" y1="2" x2="12" y2="8.5"></line>
//   </svg>
// );

// export default IconCodepen;
